'use strict';;
(function ($, window, document) {

	// ----------------------------------- Start : Setting
	// Window 相關設定
	var w = window,
		win = $(window),
		ww,
		wh,
		ws;

	// 取得Window設定值
	var windowSetting = function () {
		ww = win.innerWidth();
		wh = win.innerHeight();
		ws = win.scrollTop();
	}
	windowSetting();
	registerListener('resize', windowSetting);
	// registerListener('scroll', function(){ ws = win.scrollTop() }); // 移動到檔案最下方

	// 取得裝置判斷
	// isMobile 判斷
	var isMobile = false;
	if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
		isMobile = true
	}

	var isPhone = ww <= 639;
	var isTable = ww <= 1023;
	// ----------------------------------- End : Setting




	// Deco Text | 背景裝飾文字
	var buildDecoText = function () {
		var decoText = document.querySelectorAll('.deco-text');
		[].forEach.call(decoText, function (el) {
			var textArray = el.innerText.split(/(?!$)/u);
			el.innerText = "";
			for (var i = 0; i < textArray.length; i++) {
				var _span = document.createElement('span');
				if (textArray[i] != " ") {
					_span.innerText = textArray[i];
				} else {
					_span.innerHTML = '&nbsp;';
				}
				el.appendChild(_span);
				el.innerHTML += "\n";
			}
		});
	}
	buildDecoText();




	// ------------------------------------------- Start: Index Banner Slider
	// Index Banner Slider
	var SwiperItem1 = '#index-banner-slider';
	var $SwiperItem1 = $(SwiperItem1);
	var SwiperItem1__Pagination = $SwiperItem1.find('.swiper-pagination');
	var SwiperItem1__BtnPrev = $SwiperItem1.find('.swiper-button-prev');
	var SwiperItem1__BtnNext = $SwiperItem1.find('.swiper-button-next');
	var SwiperItem1__BtnNextCircle; // Next JS
	var detctedDevice;
	var mySwiperItem1__autoplay = 11000; //autoPlay時間


	// 確認是否要 Loop 跟 Navigation
	var SwiperItem1__hasLoop = $SwiperItem1.find('.swiper-slide').length > 1;
	if (SwiperItem1__hasLoop) $SwiperItem1.find('.swiper-nav').removeClass('hide');


	function resizeIndexBanner() {

		if ($SwiperItem1.length === 0) return false;

		var indexBanner1 = $SwiperItem1.find('.index-banner-1'),
			imgDeco = indexBanner1.find('.img-deco'),
			bw = indexBanner1.innerWidth(),
			bh = indexBanner1.innerHeight(),
			imgW, imgH,
			nw, nh,
			isWidth, nLeft, nTop; // 確認是100%寬 / 100%高

		if (ww >= 1200) {
			// 1200 ~ ：使用CSS設定
			imgDeco.css({
				'width': '',
				'height': '',
				'top': '',
				'left': ''
			})
		} else {
			if (ww >= 640) {
				// 640 ~ 1199，區塊高：calc(100vh - 75px)，圖片大小(大圖) 1920*960
				imgW = 1920;
				imgH = 960;
			} else {
				// 0 ~ 639，區塊高：(100vh - 75px)，圖片大小(小圖) 900*1440
				imgW = 900;
				imgH = 1440;
			}

			// 計算大小
			if (bw / bh > imgW / imgH) {
				// 100%寬
				nw = bw;
				nh = imgH / imgW * bw;
				nLeft = 0;
				nTop = (nh - bh) / 2 * (-1);
			} else {
				// 100%高
				nh = bh;
				nw = imgW / imgH * bh;
				nTop = 0;
				nLeft = (nw - bw) / 2 * (-1);
			}

			// 如果是 0 ~ 639 計算出來的為背景圖(900*1440)Cover後的大小
			// 要重新計算轉為1920*960 比例的大小
			if (ww < 640) {
				nTop = nTop + nh * 215.176 / 1438.367;
				nLeft = nLeft - nw * 721.746 / 898.978;
				nw = nw * 2610.488 / 898.978;
				nh = nh * 1308.43 / 1438.67;
			}

			imgDeco.css({
				'width': nw + 'px',
				'height': nh + 'px',
				'top': nTop + 'px',
				'left': nLeft + 'px',
			});
		}
	}
	resizeIndexBanner();
	registerListener('resize', resizeIndexBanner);


	// -----------------------------------
	// Video 準備
	var sliderVideo;
	if ($SwiperItem1.length > 0) {
		sliderVideo = document.querySelector(SwiperItem1).querySelectorAll('.video-box');
	}

	// Img(LazyLoad形式)
	var mySwiperItem1__videoImg = function () {
		[].forEach.call(sliderVideo, function (el) {
			var youTubsrc = el.getAttribute('data-video');
			var youTubeId = getYoutubeId(youTubsrc);
			// var ytImg = 'https://img.youtube.com/vi/'+ youTubeId +'/0.jpg';
			var ytImg = 'https://img.youtube.com/vi/' + youTubeId + '/maxresdefault.jpg';

			var vImg = document.createElement('div');
			vImg.className = 'swiper-lazy img';
			vImg.setAttribute('data-background', ytImg);

			el.videoId = youTubeId;
			el.children[0].appendChild(vImg);
		});
		mySwiperItem1__videoSize();
	}

	// Video RWD(16:9)
	var mySwiperItem1__videoSize = function () {
		var newW, newH;
		var plusUIHeight = 80;
		if (ww >= 1200) {
			// PC (圖片區塊: 1:2)
			newH = ww * 9 / 16 + plusUIHeight;
			newW = newH * 16 / 9;
		} else {
			// Mobile + 小電腦 (圖片區塊: 100vh - 75px)
			var sliderBoxW = ww,
				sliderBoxH = wh - 75;
			if (sliderBoxW / sliderBoxH > 16 / 9) {
				// 100% 寬，比例高
				newH = sliderBoxW * 9 / 16 + plusUIHeight;
				newW = newH * 16 / 9;
			} else {
				// 100% 高，比例寬
				newH = sliderBoxH + plusUIHeight;
				newW = newH * 16 / 9;
			}
		}
		[].forEach.call(sliderVideo, function (el) {
			var embedBox = el.children[0];
			embedBox.style.width = newW + 'px';
			embedBox.style.height = newH + 'px';
		});
	}


	// YouTube 影片 置入
	var players = [];
	var videoHasBuildClass = 'has-build';
	var youTubeSetting = '?rel=0&modestbranding=1&autoplay=1&version=3&enablejsapi=1';
	var ytAPI_loaded = false;
	var videoIndex = 0;
	var videoActive = null;
	var mySwiperItem1__videoPlay = function (item) {
		// Mobile 不放 Video避免耗流量
		if (isMobile) return false;

		// 停止Video
		mySwiperItem1__videoStop();

		var videoBox = item.slides[item.activeIndex].querySelector('.video-box');
		if (videoBox == null) return false;

		if (videoBox.classList.contains(videoHasBuildClass)) {
			// 已Build => Play
			videoActive = players[videoBox.videoIdNum];
			videoActive.playVideo();
		} else {
			// 尚未Build => Build => Play
			var youTubeId = videoBox.videoId;
			var videoIframeSrc = 'https://www.youtube.com/embed/' + youTubeId + youTubeSetting;
			var youTubeIframeString = '<iframe width="560" height="315" class="video-player" src="' + videoIframeSrc + '" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen data-videoid="' + videoIndex + '"></iframe>';

			videoBox.classList.add(videoHasBuildClass);
			videoBox.querySelector('.responsive-embed').innerHTML = youTubeIframeString;

			// 計算Index數字
			videoBox.videoIdNum = videoIndex;
			videoIndex++;

			var videoBox__iframe = videoBox.querySelector('iframe');

			if (!ytAPI_loaded) {
				ytAPI_load(videoBox__iframe);
				ytAPI_loaded = true;
			} else {
				var player = new YT.Player(videoBox__iframe, {
					events: {
						'onReady': onPlayerReady,
						'onStateChange': onPlayerStateChange
					}
				});

				players.push(player);
			}
		}
	}

	var mySwiperItem1__videoStop = function () {
		if (videoActive != null) {
			videoActive.pauseVideo();
			videoActive = null;
		}
	}

	function ytAPI_load(el) {
		// 讀取YouTube API
		// Dynamically load JS inside JS [duplicate]: https://stackoverflow.com/a/14521482
		var script = document.createElement('script');
		script.onload = function () {
			// do stuff with the script

			// YouTube API 多重物件
			// Loading multiple video players with youtube api: https://stackoverflow.com/a/51789311
			window.onYouTubeIframeAPIReady = function () {
				// Create the YT player
				var player = new YT.Player(el, {
					events: {
						'onReady': onPlayerReady,
						'onStateChange': onPlayerStateChange
					}
				});

				// add to players array
				players.push(player);
			};
		};
		script.src = '//www.youtube.com/iframe_api';
		document.head.appendChild(script); //or something of the likes
	}

	// YouTube影片改變狀態Event
	// http://jsfiddle.net/t24U2/
	var  ytKeyOnStage = null;
	var  ytKeyOnEl = null;
	function onPlayerStateChange(event) {

		var obj = event.target

		// 避免YouTube經常性的換API名稱
		if(!ytKeyOnStage) {
			Object.keys(obj).map(function(key, index) {
				if(obj[key] !== null && typeof obj[key] === 'object') {
					if(obj[key].tagName !== undefined) {
						ytKeyOnEl = key
					} else if (obj[key].onStateChange !== undefined) {
						ytKeyOnStage = key
					}
				}
			});
		}

		// https://stackoverflow.com/a/30105853
		if (event.data === YT.PlayerState.ENDED) {
			var player = players[parseInt(obj[ytKeyOnEl].getAttribute('data-videoid'))];
			player.playVideo();
		}

		obj[ytKeyOnStage].onStateChange = false;

		var targetParent = obj[ytKeyOnEl].parentElement.parentElement.parentElement;
		targetParent.classList.add('has-played');
	}

	function onPlayerReady(event) {
		// To Play or Pause Video
		videoActive = event.target;
	}

	// -----------------------------------
	// Banner Swiper
	var mySwiperItem1 = undefined;

	function buildSwiperItem1() {
		mySwiperItem1 = new Swiper(SwiperItem1, {
			speed: 400,
			autoplay: {
				delay: mySwiperItem1__autoplay,
				disableOnInteraction: false
			},
			loop: SwiperItem1__hasLoop,
			slidesPerView: 'auto',
			centeredSlides: true,
			lazy: {
				loadPrevNext: true,
			},
			allowTouchMove: SwiperItem1__hasLoop,
			navigation: {
				nextEl: SwiperItem1__BtnNext,
				prevEl: SwiperItem1__BtnPrev,
			},
			pagination: {
				el: SwiperItem1__Pagination,
				clickable: true,
				renderBullet: function (index, className) {
					var otherClass = '';
					if (index == this.pagination.perView)
						otherClass = ' bullet-extra'
					else if (index > this.pagination.perView)
						otherClass = ' bullet-hide'
					return '<span class="' + className + otherClass + '"></span>';
				},
			},
			on: {
				init: function () {
					swiperBulletsAniInit(this);
				},
				slideChange: function () {
					swiperBulletsAni(this);
					mySwiperItem1__videoPlay(this);
					SwiperItem1__BtnNext.find('.circle').circleProgress({
						animationStartValue: 0,
						animation: {
							duration: mySwiperItem1__autoplay + 400
						}
					});
				},
				lazyImageReady: detctedDevice,
				resize: mySwiperItem1__videoSize
			}
		});

		// Stop Autoplay for fade-in animation
		if (mySwiperItem1.autoplay.running) {
			mySwiperItem1.autoplay.stop();
			setTimeout(() => {
				mySwiperItem1.autoplay.start();
				SwiperItem1__BtnNext.find('.circle').circleProgress();
			}, 3000);
		}
	}
	if ($SwiperItem1.length > 0) {
		mySwiperItem1__videoImg();
		registerListener('load', buildSwiperItem1);
	}

	// Circle
	var buildCircleProgress = function () {
		if (typeof $().circleProgress == 'function') {
			// Start and build pause / play
			// https://github.com/kottenator/jquery-circle-progress/issues/37
			var option = {
				value: 1,
				size: 46,
				thickness: 2,
				startAngle: -Math.PI / 2,
				animationStartValue: 0,
				fill: {
					gradient: ["white"]
				},
				animation: {
					duration: mySwiperItem1__autoplay,
					easing: "linear"
				}
			}
			// Start
			var $circle = SwiperItem1__BtnNext.find('.circle'),
				circle = $circle.get(0);
			$circle.circleProgress(option);

			// Build play
			circle.play = function () {
				// var palyOption = option;
				// palyOption.animationStartValue = this.stepValueLeft || 0;
				// palyOption.animation.duration = (mySwiperItem1__autoplay + 400) * (1 - this.stepValueLeft);

				// console.log(palyOption.animationStartValue)

				// $(this).circleProgress(palyOption);


				$(this).circleProgress(option);
				circle.isStop = false;
			}

			// Build stop
			circle.stop = function () {
				$($(this).circleProgress('widget')).stop();
				// var canvasVal = this.querySelector('canvas').animationProgress;
				// this.stepValueLeft = canvasVal;
				// console.log(canvasVal)
				circle.isStop = true;
			}

			SwiperItem1__BtnNextCircle = circle;
		}
	}
	if (SwiperItem1__BtnNext.find('.circle').length > 0) {
		registerListener('load', buildCircleProgress);
	}



	function detctedDevice(slideEl, imageEl) {
		if (document.documentElement.browser === 'IE') {
			// Fixed IE Object Fit
			var imgsrc = $(slideEl).find('.src-medium').attr('data-srcset');
			imageEl.style.backgroundImage = 'url(' + imgsrc + ')';

		} else if (ww > 640) {
			// Not IE && Is Tablet + PC
			var imgsrc = $(slideEl).find('.src-medium').attr('data-srcset');
			$(imageEl).attr('srcset', imgsrc);
		}
	}

	// Resize: 換大/小圖
	var mySwiperItem1_imgBreakpoint = window.matchMedia('(min-width:640px)');
	var mySwiperItem1_imgBreakpointChecker = function () {
		// 確認尋找大圖小圖
		var findClass;
		if (mySwiperItem1_imgBreakpoint.matches === true) {
			findClass = 'src-medium';
		} else if (mySwiperItem1_imgBreakpoint.matches === false) {
			findClass = 'src-small';
		}

		var target = mySwiperItem1.slides;
		for (var i = 0; i < target.length; i++) {
			var currentImg = target[i].querySelector('.swiper-lazy-loaded');
			if (currentImg != null && currentImg.parentElement.classList.contains('img-box')) {
				// Img 確實存在 & 已經Load完成
				var imgSrc = currentImg.parentElement.querySelector('.' + findClass).getAttribute('data-srcset');
				if (document.documentElement.browser !== 'IE') {
					// Not IE: 無視使用640寬小螢幕的IE
					currentImg.setAttribute('srcset', imgSrc)
				}
			}
		}
	};
	if ($SwiperItem1.length > 0) {
		mySwiperItem1_imgBreakpoint.addListener(mySwiperItem1_imgBreakpointChecker);
	}

	// 停止autoplay
	function swiperItem1__autoplayStop() {
		if ($SwiperItem1.length > 0 && mySwiperItem1 != undefined) {

			// Swiper Autoplay
			mySwiperItem1.autoplay.stop();

			// Video
			if (videoActive != null) {
				videoActive.pauseVideo();
			}

			// 圓動畫
			if (SwiperItem1__BtnNextCircle != undefined) {
				SwiperItem1__BtnNextCircle.stop();
			}
		}
	}

	// 恢復autoplay
	function swiperItem1__autoplayPlay() {
		if ($SwiperItem1.length > 0 && mySwiperItem1 != undefined) {

			// Swiper Autoplay
			mySwiperItem1.autoplay.start();

			// Video
			if (videoActive != null) {
				videoActive.playVideo();
			}

			// 圓動畫
			if (SwiperItem1__BtnNextCircle != undefined && SwiperItem1__BtnNextCircle.isStop) {
				SwiperItem1__BtnNextCircle.play();
			}
		}
	}

	// Scroll
	function swiperItem1__scrollDetecting() {
		if ($SwiperItem1.length > 0 && mySwiperItem1 != undefined) {
			if (ws > $SwiperItem1.offset().top + $SwiperItem1.innerHeight() * 1.5) {
				// 超過一定高度 => 停止autoplay
				swiperItem1__autoplayStop();
			} else {
				// 恢復autoplay && video Play
				swiperItem1__autoplayPlay();
			}
		}
	}

	// Window Blur / Focus
	function windowFocusBlur(event) {
		if (event.type === "blur") {
			// Window Blur => 停止autoplay
			swiperItem1__autoplayStop();
		} else if (event.type === "focus") {
			// Window Focus => 恢復autoplay
			swiperItem1__autoplayPlay();
		}
	}
	// if($SwiperItem1.length > 0){
	// 	registerListener('blur', windowFocusBlur);
	// 	registerListener('focus', windowFocusBlur);
	// }


	// Banner 1 Animation
	// var baaner1 = undefined,
	// 	banner1_particle1,
	// 	banner1_particle2,
	// 	banner1_particle3,
	// 	banner1_particle4,
	// 	baaner1_imgLighter,
	// 	baaner1_svg,
	// 	baaner1_animation = [];
	// 	baaner1_animation[0] = [];	// 放Item
	// 	baaner1_animation[1] = [];	// 放動畫
	// function SwiperItem1__isBanner1(item){

	// 	if(ww < 1024 || isMobile) return false;

	// 	// Get Banner 1 Itme
	// 	if( baaner1 === undefined){
	// 		baaner1 = $SwiperItem1.find('.index-banner-1');

	// 		// Particle
	// 		banner1_particle1 = baaner1.find('.particle-1');
	// 		banner1_particle2 = baaner1.find('.particle-2');
	// 		banner1_particle3 = baaner1.find('.particle-3');
	// 		banner1_particle4 = baaner1.find('.particle-4');
	// 		baaner1_animation[0].push(banner1_particle1);
	// 		baaner1_animation[0].push(banner1_particle2);
	// 		baaner1_animation[0].push(banner1_particle3);
	// 		baaner1_animation[0].push(banner1_particle4);
	// 		var b1Ani1 = TweenMax.to(banner1_particle1, 60, {y:"-2560px", ease:Linear.easeNone, repeat:-1}).pause(),
	// 			b1Ani2 = TweenMax.to(banner1_particle2, 120, {y:"-2560px", ease:Linear.easeNone, repeat:-1}).pause(),
	// 			b1Ani3 = TweenMax.to(banner1_particle3, 180, {y:"-2560px", ease:Linear.easeNone, repeat:-1}).pause(),
	// 			b1Ani4 = TweenMax.to(banner1_particle4, 600, {y:"-2560px", ease:Linear.easeNone, repeat:-1}).pause();
	// 		baaner1_animation[1].push(b1Ani1);
	// 		baaner1_animation[1].push(b1Ani2);
	// 		baaner1_animation[1].push(b1Ani3);
	// 		baaner1_animation[1].push(b1Ani4);

	// 		// Img(lighter)
	// 		baaner1_imgLighter = baaner1.find('.lighter');
	// 		baaner1_animation[0].push(baaner1_imgLighter);
	// 		var tl = new TimelineLite();
	// 		tl
	// 			.to(baaner1_imgLighter, 0, { opacity: 0 })
	// 			.to(baaner1_imgLighter, 2, { opacity: 0.8 })
	// 			.to(baaner1_imgLighter, 1, { opacity: 0.8 })
	// 			.to(baaner1_imgLighter, 2, { opacity: 0 });
	// 		var b1light = TweenMax.to(tl, 5, {progress: 1, yoyo: true, repeat: -1, ease:Linear.easeNone}).pause();
	// 		baaner1_animation[1].push(b1light);

	// 		// Svg
	// 		baaner1_svg = baaner1.find('.svg');
	// 		baaner1_animation[0].push(baaner1_svg);
	// 		var b1Svg = TweenMax.fromTo(baaner1_svg, 2.5, {opacity: 0}, {opacity: 0.8, ease:Linear.easeNone, yoyo:true, repeat:-1}).pause();
	// 		baaner1_animation[1].push(b1Svg);
	// 	}

	// 	if(item.realIndex === 0){
	// 		for(var i = 0; i < baaner1_animation[0].length; i++){
	// 			baaner1_animation[1][i].play();
	// 		}
	// 	}else{
	// 		for(var i = 0; i < baaner1_animation[0].length; i++){
	// 			baaner1_animation[1][i].pause();
	// 		}
	// 	}
	// }



	// ---------------------------------------------------------
	// 動態隱藏Dot
	// 動態隱藏Dot - 要設置Init
	function swiperBulletsAniInit(item) {
		if (item != undefined) {
			item.activeIndexOld = 0; // 用來記錄Old
			item.pagination.perView = 4; // Pagination 最多一次顯示多少（不含縮小、被隱藏點）
		}
	}
	// 動態隱藏Dot - Change 事件
	function swiperBulletsAni(item) {
		if (item != undefined) {

			if (item.pagination.bullets == undefined || item.pagination.bullets.length <= 5) return false;

			var activeIndex = item.realIndex;
			var sliderBullets = item.pagination.bullets;
			var sliderLength = sliderBullets.length;
			var pagiPerView = item.pagination.perView;

			var thisBullet = sliderBullets[activeIndex];
			var dir = (activeIndex - item.activeIndexOld > 0) ? 'next' : 'prev';

			if (thisBullet != undefined) {

				// 如果是有extra的
				if (thisBullet.className.match(/\bbullet-extra\b/) != null) {
					thisBullet.classList.remove('bullet-extra');

					// 移除鄰近的 Hide Class
					var nearBullet = thisBullet.previousSibling
					if (dir == 'next') {
						nearBullet = thisBullet.nextSibling;
					}
					if (nearBullet != null) {
						nearBullet.classList.remove('bullet-hide');
						nearBullet.classList.add('bullet-extra');
					}

					// 增加遠端的extra
					var farBullet = sliderBullets[activeIndex + pagiPerView];
					if (dir == 'next') {
						farBullet = sliderBullets[activeIndex - pagiPerView];
					}
					if (farBullet != null) {
						farBullet.classList.remove('bullet-hide');
						farBullet.classList.add('bullet-extra');
					}

					// 增加遠端的hide
					var farfarBullet = sliderBullets[activeIndex + (pagiPerView + 1)];
					if (dir == 'next') {
						farfarBullet = sliderBullets[activeIndex - (pagiPerView + 1)];
					}
					if (farfarBullet != null) {
						farfarBullet.classList.remove('bullet-extra');
						farfarBullet.classList.add('bullet-hide');
					}
				} else if (thisBullet.className.match(/\bbullet-hide\b/) != null) {
					// 如果是Hide (因Loop產生)
					thisBullet.classList.remove('bullet-hide');

					for (var i = 1; i < sliderLength; i++) {
						if (dir == 'next') {
							// 0 -> last : Next
							var farBullet = sliderBullets[activeIndex - i]
						} else {
							// last -> 0 : Prev
							var farBullet = sliderBullets[activeIndex + i]
						}
						if (i < pagiPerView) {
							farBullet.classList.remove('bullet-extra');
							farBullet.classList.remove('bullet-hide');
						} else if (i == pagiPerView) {
							farBullet.classList.add('bullet-extra');
							farBullet.classList.remove('bullet-hide');
						} else {
							farBullet.classList.remove('bullet-extra');
							farBullet.classList.add('bullet-hide');
						}
					}
				}
			}
			item.activeIndexOld = activeIndex;
		}
	}
	// -------------------------------------------
	// 讀取網址
	// get-youtube-info.js: https://gist.github.com/ramonvictor/5629626
	function getYoutubeId(url) {
		var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
		var match = url.match(regExp);
		if (match && match[7].length == 11) {
			return match[7];
		}
		return false;
	}
	// ------------------------------------------- End: Index Banner Slider









	// ------------------------------------------ Start: Swiper breakpoint Detected

	// keep track of swiper instances to destroy later
	var mySwiperItem2 = undefined;
	var mySwiperItem3 = undefined;

	// ----------------------
	// Swiper breakpoint Detected
	var breakpoint_medium = window.matchMedia('(min-width:1024px)');

	var breakpointChecker_medium = function () {
		// if larger viewport and multi-row layout needed
		if (breakpoint_medium.matches === true) {
			// clean up old instances and inline styles when available
			if (mySwiperItem2 !== undefined) mySwiperItem2.destroy(true, true);
			if (mySwiperItem3 !== undefined) mySwiperItem3.destroy(true, true);
			return;
		} else if (breakpoint_medium.matches === false) {
			return enableSwiper_medium();
		}
	};

	// Add Swiper buildHandler
	var enableSwiper_medium = function () {
		// $('#index-zero-slider').find('.swiper-wrapper').height('');
		buildSwiperItem2();
		buildSwiperItem3();
	};

	// keep an eye on viewport size changes
	breakpoint_medium.addListener(breakpointChecker_medium);

	// start
	registerListener('load', breakpointChecker_medium);


	// -------------------------------------------
	// Zero-to-Market
	// Swiper Setting
	var SwiperItem2 = '#index-zero-slider';
	var $SwiperItem2 = $(SwiperItem2);

	function buildSwiperItem2() {
		var SwiperItem2 = '#index-zero-slider';
		var SwiperItem2BtnNext = $(SwiperItem2).children('.swiper-button-next');
		var SwiperItem2BtnPrev = $(SwiperItem2).children('.swiper-button-prev');

		if ($(SwiperItem2).length < 1) return false;

		mySwiperItem2 = new Swiper(SwiperItem2, {
			speed: 400,
			// loop: true,
			autoHeight: true,
			spaceBetween: 30,
			lazy: {
				loadPrevNext: true,
			},
			navigation: {
				nextEl: SwiperItem2BtnNext,
				prevEl: SwiperItem2BtnPrev,
			},
			breakpoints: {
				// when window width is <= 639px
				639: {
					slidesPerView: 1,
				},
				// when window width is <= 1199px
				1023: {
					slidesPerView: 'auto',
					centeredSlides: true
				}
			},
			on: {
				slideChange: function () {
					zeroSliderChange(this)
				}
			}
		});
	};

	var zeroStatus = ['is-active', 'is-next', 'is-next-far', 'is-prev-far', 'is-prev'];
	// Swiper Change Event
	function zeroSliderChange(item) {
		var activeIndex = item.realIndex;
		for (var i = 0; i < item.slides.length; i++) {
			item.slides[(activeIndex + i) % 5].setAttribute('data-status', zeroStatus[i]);
		}
	}
	// 1024+ : Click Event
	var zeroSlider = $SwiperItem2.find('.swiper-slide');
	zeroSlider.on('click', 'a', function (event) {
		if (ww >= 1024 && this.parentElement.getAttribute("data-status") != 'is-active') {
			event.preventDefault();
			var _this = this;
			_this.blur();
			var currentActive = $(this.parentElement).index();
			for (var i = 0; i < zeroSlider.length; i++) {
				zeroSlider.eq((currentActive + i) % 5).attr('data-status', zeroStatus[i]);
			}

			setTimeout(function () {
				var nh = $(_this).innerHeight();
				$SwiperItem2.find('.swiper-wrapper').height(nh + 50);
			}, 500);
		}
	});

	if (ww >= 1024) {
		var nh = $SwiperItem2.find('.swiper-slide[data-status="is-active"]>a').innerHeight();
		$SwiperItem2.find('.swiper-wrapper').height(nh + 50);
	}



	// ------------------------------------------- Start : Different
	// Different
	function buildSwiperItem3() {
		var SwiperItem3 = '#index-differ-slider';
		var SwiperItem3BtnNext = $(SwiperItem3).parent().children('.swiper-button-next');
		var SwiperItem3BtnPrev = $(SwiperItem3).parent().children('.swiper-button-prev');

		if ($(SwiperItem3).length < 1) return false;

		mySwiperItem3 = new Swiper(SwiperItem3, {
			speed: 400,
			autoHeight: true,
			spaceBetween: 30,
			lazy: {
				loadPrevNext: true,
			},
			navigation: {
				nextEl: SwiperItem3BtnNext,
				prevEl: SwiperItem3BtnPrev,
			},
			breakpoints: {
				// when window width is <= 639px
				639: {
					slidesPerView: 1,
				},
				// when window width is <= 1199px
				1023: {
					slidesPerView: 'auto',
					centeredSlides: true
				}
			},
		});
	};

	$('#index-differ-slider').on('click', '.swiper-slide', function () {
		$(this).addClass('is-active');
		$(this).siblings('.is-active').removeClass('is-active');
	});
	// ------------------------------------------- End : Different


	var onScrollEventHandler = function () {
		ws = win.scrollTop();
		swiperItem1__scrollDetecting();
	}
	registerListener('scroll', onScrollEventHandler);


})(jQuery, window, document);